import { loginUrl } from "const/urls";
import { useAuth } from "hooks/authContext";
import { useEffect, useCallback, useRef } from "react";

export function useLogout() {
  const url = loginUrl;
  const context = useAuth();
  return useCallback(() => {
    const previousLocation = window.location.pathname + window.location.search;
    const previousCompanyInfo = context.companyInfo;
    localStorage.clear();
    if (previousLocation !== "/404") {
      localStorage.setItem("previousLocation", previousLocation);
    }

    if (previousCompanyInfo) {
      localStorage.setItem("previousCompanyInfo", previousCompanyInfo.id);
    }
    window.location.replace(url);
  }, [url, context.companyInfo]);
}
