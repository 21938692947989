import axios from "axios";
import { loginUrl } from "const/urls";
import { StylesConfig } from "react-select";
import { GeocoderAddressComponent } from "types/googleApiTypes";
import { ISelectOption } from "types/utility";

export function getStylesForReactSelectInputs(
  config: StylesConfig<ISelectOption> = {}
) {
  const customStyles: StylesConfig<ISelectOption> = {
    control: (base, state) => {
      const styles = {
        ...base,
        borderColor: "",
        border: state.isFocused ? 0 : 0,
        boxShadow: "0",
        backgroundColor: "gray-300",
      };
      return styles;
    },
    container: (base, state) => {
      const styles = {
        ...base,
        borderColor: "",
        border: "0",
        boxShadow: "0",
        backgroundColor: "gray-300",
      };
      return styles;
    },
    input: (base, state) => {
      const styles = {
        ...base,
      };
      return styles;
    },
    multiValueRemove: (base, state) => {
      const styles = {
        ...base,
        borderColor: "",
        border: 0,
        boxShadow: "0",
        color: "black",
        "&:hover": {
          backgroundColor: "#E6E5E5",
          opacity: 100,
          color: "black",
        },
      };
      return styles;
    },
    placeholder: (base, state) => {
      return { ...base, fontSize: "14px", color: "#9da4b1" };
    },
    singleValue: (base, state) => {
      return {
        ...base,
        color: "#333333",
        lineHeight: "17px",
        fontSize: "14px",
        fontWeight: "400",
      };
    },
    valueContainer: (base, state) => {
      return {
        ...base,
        boxShadow: "none !important",
        border: "0 !important",
        "&:hover": {
          border: "0 !important",
        },
      };
    },
    menu: (base, state) => {
      return { ...base, borderRadius: "3px" };
    },
    multiValue: (base, state) => {
      return { ...base, borderRadius: "5px" };
    },
    indicatorsContainer: (base) => {
      return { ...base, transform: "scale(0.7)" };
    },
    menuList: (base, state) => {
      return { ...base, paddingTop: "8px", paddingBottom: "8px" };
    },
    option: (base, state) => {
      //   if (state.isSelected) {
      //     provided.backgroundColor = "#dfe3eb"; //
      //     provided.color = "inherit";
      //   }

      return {
        ...base,
        // color: "#333333",
        padding: "2px 12px",
        fontSize: "14px",
        fontWeight: "400",
      };
    },
    ...config,
  };
  return customStyles;
}

interface IPropertyCheck {
  key: string;
  type: string;
}

function conformsToInterface<T>(obj: any, checks: IPropertyCheck[]): obj is T {
  return checks.every((check) => {
    return typeof obj[check.key] === check.type;
  });
}

export function iterateArrayByObjectType<T>(
  arr: any[],
  method: "filter" | "find",
  checks: IPropertyCheck[]
): T[] | T {
  switch (method) {
    case "filter":
      return arr.filter((item) => {
        const preparedItem = removeFieldsFromObject(item, [
          "order_access",
          "order_notification",
        ]);

        return conformsToInterface<T>(preparedItem, checks);
      });
    case "find":
      return arr.find((item) => {
        const preparedItem = removeFieldsFromObject(item, [
          "order_access",
          "order_notification",
        ]);
        return conformsToInterface<T>(preparedItem, checks);
      });

    default:
      return [];
  }
}

export function removeFieldsFromObject(obj: any, fields: string[]) {
  const preparedObject = obj;
  fields.forEach((field) => {
    delete preparedObject[field];
  });
  return preparedObject;
}

export function logoutUtility() {
  const previousLocation = window.location.pathname + window.location.search;
  const previousCompanyInfo = JSON.parse(
    localStorage.getItem("currentCompany") || ""
  );
  localStorage.clear();
  if (previousCompanyInfo?.id) {
    localStorage.setItem("previousCompanyInfo", previousCompanyInfo?.id);
  }
  if (previousLocation !== "/404") {
    localStorage.setItem("previousLocation", previousLocation);
  }
  window.location.replace(loginUrl);
}

function normalizeAddress1Route(
  addressComponentArray: GeocoderAddressComponent[]
) {
  const address1 = addressComponentArray.find((component) =>
    component.types.find((type) => type === "route")
  );
  if (address1) {
    return address1.short_name;
  } else {
    return "";
  }
}
function normalizeAddress1StreetNumber(
  addressComponentArray: GeocoderAddressComponent[]
) {
  const address1 = addressComponentArray.find((component) =>
    component.types.find((type) => type === "street_number")
  );
  if (address1) {
    return address1.short_name;
  } else {
    return "";
  }
}

function normalizeAddress2(addressComponentArray: GeocoderAddressComponent[]) {
  const address2 = addressComponentArray.find((component) =>
    component.types.find((type) => type === "premise" || type === "sub_premise")
  );
  if (address2) {
    return address2.short_name;
  } else {
    return "";
  }
}

function normalizeCity(addressComponentArray: GeocoderAddressComponent[]) {
  const city = addressComponentArray.find((component) =>
    component.types.find((type) => type === "locality")
  );
  if (city) {
    return city.short_name;
  } else {
    return "";
  }
}

function normalizeState(addressComponentArray: GeocoderAddressComponent[]) {
  const state = addressComponentArray.find((component) =>
    component.types.find((type) => type === "administrative_area_level_1")
  );
  if (state) {
    return state.short_name;
  } else {
    return "";
  }
}

function normalizeCounty(addressComponentArray: GeocoderAddressComponent[]) {
  const county = addressComponentArray.find((component) =>
    component.types.find((type) => type === "administrative_area_level_2")
  );
  if (county) {
    return county.short_name;
  } else {
    return "";
  }
}

function normalizeNeighbourhood(
  addressComponentArray: GeocoderAddressComponent[]
) {
  const nbrhd = addressComponentArray.find((component) =>
    component.types.find((type) => type === "neighborhood")
  );
  if (nbrhd) {
    return nbrhd.short_name;
  } else {
    return "";
  }
}

function normalizeSubpremise(
  addressComponentArray: GeocoderAddressComponent[]
) {
  const subpremise = addressComponentArray.find((component) =>
    component.types.find((type) => type === "subpremise")
  );
  if (subpremise) {
    return subpremise.short_name;
  } else {
    return "";
  }
}

function normalizeCountry(addressComponentArray: GeocoderAddressComponent[]) {
  const country = addressComponentArray.find((component) =>
    component.types.find((type) => type === "country")
  );
  if (country) {
    return country.short_name;
  } else {
    return "";
  }
}

function normalizeZipPostalCode(
  addressComponentArray: GeocoderAddressComponent[]
) {
  const postalCode = addressComponentArray.find((component) =>
    component.types.find((type) => type === "postal_code")
  );
  if (postalCode) {
    return postalCode.short_name;
  } else {
    return "";
  }
}

export default function geocodeFormatter(
  addressComponentArray: GeocoderAddressComponent[]
) {
  return {
    address1: `${normalizeAddress1Route(
      addressComponentArray
    )} ${normalizeAddress1StreetNumber(addressComponentArray)}`,
    street: normalizeAddress1Route(addressComponentArray),
    streetNumber: normalizeAddress1StreetNumber(addressComponentArray),
    address2: normalizeAddress2(addressComponentArray),
    city: normalizeCity(addressComponentArray),
    county: normalizeCounty(addressComponentArray),
    state: normalizeState(addressComponentArray),
    neighborhood: normalizeNeighbourhood(addressComponentArray),
    subPremise: normalizeSubpremise(addressComponentArray),
    zip: normalizeZipPostalCode(addressComponentArray),
    country: normalizeCountry(addressComponentArray),
  };
}

export function getBase64(url: string) {
  return axios
    .get(url, {
      responseType: "blob",
    })
    .then((response) => {
      return new File([response.data], `template-${Date.now()}`);
    });
}

export async function compareFiles(file1: File, file2: File) {
  // they don't have the same size, they are different
  if (file1.size !== file2.size) return Promise.resolve(false);
  // load both as ArrayBuffers
  return Promise.all([readAsArrayBuffer(file1), readAsArrayBuffer(file2)]).then(
    ([buf1, buf2]) => {
      // create views over our ArrayBuffers
      const arr1 = new Uint8Array(buf1);
      const arr2 = new Uint8Array(buf2);
      return !arr1.some(
        (val, i) => arr2[i] !== val // search for diffs
      );
    }
  );
}

export function readAsArrayBuffer(file: File) {
  // we could also have used a FileReader,
  // but Response is conveniently already Promise based
  return new Response(file).arrayBuffer();
}

export function maskString(str: string, mask: string) {
  return str.replace(/./g, mask);
}

export function isTruncated(e: HTMLElement) {
  return e.offsetWidth < e.scrollWidth;
}
