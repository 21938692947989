import {
  IUpdatetedInvitedUserResponse,
  useCreateProperty,
  useInviteCompanyUsers,
  useUpdateProperty,
} from "api/query-hooks";
import { inviteCompanyUsers } from "api/requests";
import { FormikHelpers } from "formik";
import { useAuth } from "hooks/authContext";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { FIELD_NAMES, IValues } from "./CreatePropertyForm.constants";
import { useNavigate } from "react-router";
import { IGeocoderResult } from "types/googleApiTypes";
import geocodeFormatter from "utilities/common";
import { vanillaJsOriginURL } from "const/urls";
import { useSegment } from "hooks/utility/useSegment";
import { IPropertyItem } from "types/entities";
import { property } from "lodash";

export function useInitialState(
  propertyData: any,
  mode: "create" | "edit",
  props: IGeocoderResult | undefined
) {
  const normalizedResults =
    props?.properties && geocodeFormatter(props?.properties);

  const locationHasBeenChangedDuringEdit = useMemo(
    () =>
      mode === "edit" &&
      propertyData?.coordinates?.lat &&
      propertyData?.coordinates?.lon &&
      props?.center?.lat &&
      props?.center?.lng &&
      propertyData?.coordinates?.lat !== props?.center?.lat &&
      propertyData?.coordinates?.lon !== props?.center?.lat,
    [
      mode,
      propertyData?.coordinates?.lat,
      propertyData?.coordinates?.lon,
      props?.center?.lat,
      props?.center?.lng,
    ]
  );

  return useMemo(() => {
    return {
      [FIELD_NAMES.PROPERTY_NAME]: propertyData?.name || "",
      [FIELD_NAMES.COUNTRY]: locationHasBeenChangedDuringEdit
        ? normalizedResults?.country || propertyData?.address?.country || ""
        : propertyData?.address?.country || normalizedResults?.country || "",
      [FIELD_NAMES.ADDRESS_LINE_1]: locationHasBeenChangedDuringEdit
        ? normalizedResults?.address1 ||
          `${propertyData?.address.street} ${propertyData?.address?.street_number}` ||
          ""
        : propertyData
        ? `${propertyData?.address.street} ${propertyData?.address?.street_number}`
        : normalizedResults?.address1 || "",
      [FIELD_NAMES.ADDRESS_LINE_2]: locationHasBeenChangedDuringEdit
        ? normalizedResults?.address2 ||
          propertyData?.address?.address_line_2 ||
          ""
        : propertyData?.address?.address_line_2 ||
          normalizedResults?.address2 ||
          "",
      [FIELD_NAMES.CITY]: locationHasBeenChangedDuringEdit
        ? normalizedResults?.city || propertyData?.address?.city || ""
        : propertyData?.address?.city || normalizedResults?.city || "",
      [FIELD_NAMES.STATE_REGION]: locationHasBeenChangedDuringEdit
        ? normalizedResults?.state || propertyData?.address?.state || ""
        : propertyData?.address?.state || normalizedResults?.state || "",
      [FIELD_NAMES.POSTAL_CODE]: locationHasBeenChangedDuringEdit
        ? normalizedResults?.zip || propertyData?.address?.postal_code || ""
        : propertyData?.address?.postal_code || normalizedResults?.zip || "",
    };
  }, [
    locationHasBeenChangedDuringEdit,
    normalizedResults?.address1,
    normalizedResults?.address2,
    normalizedResults?.city,
    normalizedResults?.country,
    normalizedResults?.state,
    normalizedResults?.zip,
    propertyData?.address?.address_line_2,
    propertyData?.address?.city,
    propertyData?.address?.country,
    propertyData?.address?.postal_code,
    propertyData?.address?.state,
    propertyData?.address.street,
    propertyData?.address?.street_number,
    propertyData?.name,
  ]);
}

export function useSubmitHandler(
  mode: "create" | "edit",

  geocoderProps: IGeocoderResult | undefined,
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  propertyData?: IPropertyItem
) {
  const { mutateAsync } = useCreateProperty();
  const context = useAuth();
  const { mutateAsync: updateProperty } = useUpdateProperty({
    companyId: context?.companyInfo?.id || "",
  });
  const isGuest = context?.guest;
  const normalizedResults =
    geocoderProps?.properties && geocodeFormatter(geocoderProps?.properties);
  const { propertyCreatedCallback } = useSegment();
  return useCallback(
    async (props: IValues, formikHelpers: FormikHelpers<IValues>) => {
      const dataObject = {
        companyId: context?.companyInfo?.id,
        address: {
          city: props.city,
          country: props.country,
          county: normalizedResults?.county || "",
          neighborhood: normalizedResults?.neighborhood || "",
          postal_code: props.postalCode,
          street: normalizedResults?.street || "",
          street_number: normalizedResults?.streetNumber || "",
          subpremise: normalizedResults?.subPremise || "",
          state: normalizedResults?.state || "",
        },
        coordinates: {
          lon: geocoderProps?.center.lng,
          lat: geocoderProps?.center.lat,
        },
        name: props.propertyName || geocoderProps?.name,
        address_line_2: props.addressLine2,
      };

      try {
        if (
          !dataObject.coordinates ||
          !dataObject.coordinates.lat ||
          !dataObject.coordinates.lon
        ) {
          formikHelpers.setErrors({
            propertyName:
              "Property has invalid coordinates, please adjust pin location on map.",
          });
          formikHelpers.setSubmitting(false);
          setIsSubmitting(false);
          return;
        }

        formikHelpers.setSubmitting(true);
        setIsSubmitting(true);

        if (mode === "create") {
          const result = await mutateAsync(dataObject);
          if (result.status === 200 || result.status === 201) {
            const segmentData = {
              companyId: context?.companyInfo?.id,
              propertyId: result.data.projectId,
              propertyName: props.propertyName,
            };
            await propertyCreatedCallback(segmentData);
            setTimeout(() => {
              formikHelpers.setSubmitting(false);
              setIsSubmitting(false);
              window.location.replace(
                vanillaJsOriginURL +
                  `/issues?projectId=${result.data.projectId}&rcode=${
                    context.refresh
                  }&companyRef=${context?.companyInfo?.id}${
                    isGuest ? "&guest=true" : ""
                  }${
                    result.data.projectId
                      ? `&properties=${result.data.projectId}`
                      : ""
                  }`
              );
            }, 1500);
          }
        }
        if (mode === "edit" && propertyData) {
          const payload = {
            projectId: propertyData?.id,
            property_notes: propertyData?.property_notes || "",
            ...dataObject,
          };

          const result = await updateProperty({ data: payload });
          if (result.status === 200 || result.status === 201) {
            const segmentData = {
              companyId: context?.companyInfo?.id,
              propertyId: result.data.id,
              propertyName: props.propertyName,
            };
            await propertyCreatedCallback(segmentData);
            setTimeout(() => {
              formikHelpers.setSubmitting(false);
              setIsSubmitting(false);
              window.location.replace(
                vanillaJsOriginURL +
                  `/issues?projectId=${propertyData?.id}&rcode=${
                    context.refresh
                  }&companyRef=${context?.companyInfo?.id}${
                    isGuest ? "&guest=true" : ""
                  }${
                    result.data.projectId
                      ? `&properties=${result.data.projectId}`
                      : ""
                  }`
              );
            }, 1500);
          }
        }
      } catch (error: any) {
        formikHelpers.setErrors(error.response.data);
        formikHelpers.setSubmitting(false);
        setIsSubmitting(false);
      }
    },
    [
      context?.companyInfo?.id,
      context.refresh,
      normalizedResults?.county,
      normalizedResults?.neighborhood,
      normalizedResults?.street,
      normalizedResults?.streetNumber,
      normalizedResults?.subPremise,
      normalizedResults?.state,
      geocoderProps?.center.lng,
      geocoderProps?.center.lat,
      geocoderProps?.name,
      setIsSubmitting,
      mode,
      propertyData,
      mutateAsync,
      propertyCreatedCallback,
      isGuest,
      updateProperty,
    ]
  );
}
